<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰하기<router-link to="" @click.native="goBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_27">
      <div class="top">
        <div class="box">
          <h4>리뷰할 상품 카테고리</h4>
          <p>{{ goodsInfo.data ? goodsInfo.data.category_path : "" }}</p>
        </div>
      </div>
      <div class="middle">
        <div class="box gray">
          <h4>아래에서 어떤 종류의 리뷰인지 선택해주세요.</h4>
          <h5>소비자 리뷰</h5>
          <ul class="depth_4">
            <li><a href="#">신상품R</a></li>
            <li><a href="#">가성비R</a></li>
            <li><a href="#">고품질R</a></li>
            <li><a href="#">중저가R</a></li>
          </ul>
        </div>
        <div class="box">
          <h5>전문가 리뷰</h5>
          <ul class="depth_3">
            <template v-for="menu in goodsInfo.menu">
              <template v-if="parseInt(menu.st1_seq) === 2">
                <template v-for="option2 in menu">
                  <li
                    class="option1"
                    v-if="option2.st2_title"
                    :data-option_category1="option2.st2_seq"
                    :data-option_title1="option2.st2_title"
                    :key="`2st_${option2.st2_seq}`"
                  >
                    <router-link to="" @click.native="setClick(1, $event)">{{
                      option2.st2_title
                    }}</router-link>
                  </li>
                </template>
              </template>
            </template>
          </ul>
        </div>
        <div class="box">
          <h4>아래에서 상품 사용기간을 선택해주세요</h4>
          <ul class="depth_4">
            <template v-for="menu in goodsInfo.menu">
              <template v-if="parseInt(menu.st1_seq) === 10">
                <template v-for="option2 in menu">
                  <li
                    class="option2"
                    v-if="option2.st2_title"
                    :data-option_category2="option2.st2_seq"
                    :data-option_title2="option2.st2_title"
                    :key="`2st_${option2.st2_seq}_term`"
                  >
                    <router-link to="" @click.native="setClick(2, $event)">{{
                      option2.st2_title
                    }}</router-link>
                  </li>
                </template>
              </template>
            </template>
          </ul>
        </div>
      </div>
      <div class="bottom_2">
        <h4>구체적인 상품명</h4>
        <div class="list">
          <div class="box" v-for="(goods, index) in data" :key="index">
            <div v-if="parseInt(index) == 0">메인 A</div>
            <div v-else-if="parseInt(index) == 1">비교 B</div>
            <div v-else-if="parseInt(index) == 2">비교 C</div>
            <div>{{ goods.goodsName }}</div>
            <div>
              <router-link to="" @click.native="deleteItem(index, $event)"
                >X</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn_2">
      <router-link to="" class="gray">취소</router-link
      ><router-link to="" class="yellow" @click.native="setReviewOption"
        >완료</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      data: JSON.parse(sessionStorage.getItem("reviewGoods")),
      option_category1: 0,
      option_category2: 0,
      option_title1: "",
      option_title2: "",
      category: this.$route.query.category,
      goodsSeq: this.$route.query.goods_seq,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("review/resetStore");

    //일단 데이터가 없으니까 가 데이터로 세션스토리지에 넣어둔다.
    // const data = [
    //   { goodsName: "남자 지갑 헬프 헬프", goodsSeq: "46" },
    //   { goodsName: "남자 지갑 노노", goodsSeq: "45" },
    //   { goodsName: "테스트1 3333", goodsSeq: "42" },
    // ];

    // sessionStorage.setItem("reviewGoods", JSON.stringify(data));
    this.getGoodsInfo();
  },
  computed: {
    ...mapState("review", ["result", "goodsInfo", "msg"]),
  },
  methods: {
    goBack() {
      this.$router.push(
        `/market_goods_detail/${this.category}/${this.goodsSeq}`
      );
    },
    deleteItem(idx, event) {
      console.log(this.data);
      if (this.data.length < 3) {
        this.$toast.default(
          "전문가 리뷰는 비교 상품이 2개 이상 부터 작성할 수 있습니다. "
        );
        return false;
      }
      this.data.splice(idx, 1);
      sessionStorage.setItem("reviewGoods", JSON.stringify(this.data));
    },
    async getGoodsInfo() {
      await this.$store.dispatch("review/getGoodsInfo", {
        goods_seq: this.data[0].goodsSeq, //메인 A 상품의 정보를 가져온다.
      });
      if (!this.goodsInfo) {
        this.$toast.default("상품 정보를 가져오지 못 했습니다.");
        this.$router.push("/");
        return false;
      }
    },
    setReviewOption() {
      if (this.option_category1 === 0) {
        this.$toast.default("어떤 종류의 리뷰인지 선택해주세요.");
        return false;
      }
      if (this.option_category2 === 0) {
        this.$toast.default("상품의 사용기간을 선택해주세요.");
        return false;
      }
      this.$store.dispatch("review/addOptions", {
        option_category: 2,
        option_category1: this.option_category1,
        option_category2: this.option_category2,
        option_title1: this.option_title1,
        option_title2: this.option_title2,
      });
      this.$router.push(
        `/review/review_pro_goods_write?category_id=${this.category}&goods_seq=${this.goodsSeq}`
      );
    },
    setClick(type, event) {
      if (type === 1) {
        const closest = event.target.closest("li");
        [...document.querySelectorAll(".option1")].map((op1) =>
          op1.classList.remove("on")
        );
        this.option_category1 = closest.dataset.option_category1;
        this.option_title1 = closest.dataset.option_title1;
        closest.classList.add("on");
      } else if (type === 2) {
        const closest = event.target.closest("li");
        [...document.querySelectorAll(".option2")].map((op1) =>
          op1.classList.remove("on")
        );
        this.option_category2 = closest.dataset.option_category2;
        this.option_title2 = closest.dataset.option_title2;
        closest.classList.add("on");
      }
    },
  },
};
</script>

<style></style>
